exports.get_time = function () {
  const d = new Date();
  let time = d.getTime();
  return time;
};

exports.get_time_since = function (targetTimestamp) {
  const currentTimestamp = new Date().getTime(); // Current timestamp

  const differenceInMilliseconds = currentTimestamp - targetTimestamp;

  // Convert the difference to seconds, minutes, hours, or days as needed
  const differenceInSeconds = differenceInMilliseconds / 1000;
  const differenceInMinutes = differenceInSeconds / 60;
  const differenceInHours = differenceInMinutes / 60;
  const differenceInDays = differenceInHours / 24;

  if (parseInt(differenceInDays) !== 0) {
    return parseInt(differenceInDays).toString() + " day";
  }
  if (parseInt(differenceInHours) !== 0) {
    return parseInt(differenceInHours).toString() + " hor";
  }

  if (parseInt(differenceInMinutes) !== 0) {
    return parseInt(differenceInMinutes).toString() + " min";
  }
  if (parseInt(differenceInSeconds) !== 0) {
    return parseInt(differenceInSeconds).toString() + " sec";
  }
};
