import React from "react";
import "./Contacts.css";

// import SearchBar from "./search_bar/Contacts";

function App({ setAddContacts, setIsEdit, showIsEdit }) {
  function sldhfjkshdf() {
    setAddContacts(true);
  }

  function iohudn() {
    setIsEdit((showIsEdit) => !showIsEdit);
  }
  return (
    <div id="contacts_top_bar">
      <button onClick={sldhfjkshdf}>add contact</button>
      <button onClick={iohudn}>edit</button>
    </div>
  );
}

export default App;
