import "./App.css";
// import Llkdfjsd from "src/Svg/247-customer-service.png";

function App({ title, svg, disicein }) {
  return (
    <div className="two-card-main-card">
      <img src={svg} className="undraw-card-png" alt="logo" />
      <div className="two-card-title">
        <h3>{title}</h3>
      </div>

      <div className="two-card-disicein">
        <p>{disicein}</p>
      </div>
    </div>
  );
}

export default App;
