import React, { useState, useEffect } from "react";
import "./Clock.css";

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // Update the current time every minute
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every 60,000 milliseconds (1 minute)

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Format the time as HH:MM
  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div>
      <p id="clock_fase">{formattedTime}</p>
    </div>
  );
};

export default Clock;
