// import React, { useState } from "react";
import React from "react";
import "./title_catd.css";

const Main = ({ list_of_titles_and_ids, title_card_on_click }) => {
  // this is all a bunch of shit
  var title_carde_list = [];

  try {
    if (list_of_titles_and_ids.data.get_note_titles.id.length !== 0) {
      const list_of_ids =
        list_of_titles_and_ids.data.get_note_titles.id.split(",");
      const list_of_titles =
        list_of_titles_and_ids.data.get_note_titles.title.split(",");

      for (let index = 0; index < list_of_ids.length; index++) {
        title_carde_list.push([list_of_titles[index], list_of_ids[index]]);
      }
    }
  } catch (error) {
    console.error(error);
  }

  return (
    <div id="note_title_card_container">
      {title_carde_list.map((title, index) => (
        <div
          className="note_card"
          key={index}
          onClick={() => title_card_on_click(title)}
        >
          <p>{decodeURIComponent(title[0])}</p>
        </div>
      ))}
    </div>
  );
};

export default Main;
