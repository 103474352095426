import React, { useEffect, useRef } from "react";
import "./Chat.css";

import Input from "./chat_input/Chat_input";
// import Message from "./message/chat_message";
import GroupChatMessage from "./group_chat_message/group_chat_message";

function App() {
  var messages = [
    {
      messages_type: "group_chat_text_message",
      messages_body: "1",
      user_name: "bob",
      time_sent: 1699396913998,
    },
    {
      messages_type: "group_chat_text_message",
      messages_body: "2",
      user_name: "bob",
      time_sent: 1699396913998,
    },
  ];
  const chatLogRef = useRef(null);

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
  }, []);

  return (
    <div id="chat">
      <div id="chat_log_container">
        <div id="chat_log" ref={chatLogRef}>
          <div id="main_chat_log">
            {messages.map(function (data, index) {
              if (data.messages_type === "group_chat_text_message") {
                return (
                  <GroupChatMessage
                    user={data.user_name}
                    time_sent={data.time_sent}
                    message={data.messages_body}
                  />
                );
              }

              return <div key={index}></div>;
            })}
          </div>
        </div>
      </div>

      <Input />
    </div>
  );
}

export default App;
