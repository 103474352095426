import React from "react";
import "./Chat.css";

import Chat from "./chat_input_and_log/Chat";

function App() {
  return (
    <div id="main_chat">
      <p>test</p>
      <Chat/>
    </div>
  );
}

export default App;
