import "./sineup.css";

function App() {
  return (
    <div id="sineup_">
      <div id="sdlfhlkdjf">
        <h1> Create an Account</h1>
        <br />
        <p>
          Contact us to set up your account. Email sineup@megacomus.com with
          your business details, and we'll handle the rest.
        </p>
        <br />
        <p>Thank you for choosing MEGACOMUS!</p>
      </div>
    </div>
  );
}

export default App;
