import React, { useEffect } from "react";
import "./note_body.css";

const Main = ({ note_body, save_note }) => {
  useEffect(() => {
    // Update the value of the input using state (React way)
    document.getElementById("note_body").value = decodeURIComponent(note_body);
  }, [note_body]); // Empty dependency array ensures the effect runs only once

  return (
    <textarea
      id="note_body"
      onInput={(e) => save_note(e.currentTarget.value)}
    ></textarea>
  );
  // return <div>{decodeURIComponent(note_body)}</div>;
};

export default Main;
