import React, { useEffect, useState, useCallback } from "react";
import "./Todo.css";

import api_calls from "./api_calls"; // Import the elizabot module

import TodoColumn from "./todo_column/todo_column";

const Main = () => {
  console.log("todo");
  const token = JSON.parse(localStorage.getItem("usr_tok"));
  const [list_of_todos, set_list_of_todos] = useState({
    todo_hig: "",
    todo_low: "",
    todo_mid: "",
  });

  // Define fetchData outside of useEffect
  // Wrap the fetchData function with useCallback
  const fetchData = useCallback(async () => {
    try {
      const response = await api_calls.get_todo_from_server(token);
      set_list_of_todos(response.data.get_todo);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [token]); // Include token in the dependency array

  // useEffect to fetch data when the component mounts or when token changes
  useEffect(() => {
    fetchData();
  }, [token, fetchData]);

  async function delete_todo(index) {
    var text = "";

    if (index[0] === 0) {
      text = list_of_todos.todo_hig[index[1]];
    }

    if (index[0] === 1) {
      text = list_of_todos.todo_mid[index[1]];
    }

    if (index[0] === 2) {
      text = list_of_todos.todo_low[index[1]];
    }

    await api_calls.delete_todo(token, text, index[0]);
    fetchData();
  }

  async function add_todo(index) {
    console.log(index);
    var todo_input_text = document.getElementById(
      "todo_text_input_" + index
    ).value;
    if (todo_input_text) {
      document.getElementById("todo_text_input_" + index).value = "";

      await api_calls.set_todo(token, todo_input_text, index);
      // Fetch data again after adding a new todo
      fetchData();
    }
  }
  return (
    <div id="todo_main">
      <TodoColumn
        todo_list={list_of_todos.todo_hig}
        delete_function={delete_todo}
        add_todo={add_todo}
        position={0}
      />
      <TodoColumn
        todo_list={list_of_todos.todo_mid}
        delete_function={delete_todo}
        add_todo={add_todo}
        position={1}
      />
      <TodoColumn
        todo_list={list_of_todos.todo_low}
        delete_function={delete_todo}
        add_todo={add_todo}
        position={2}
      />
    </div>
  );
};
export default Main;
