// import React, { useState, useEffect } from "react";
import React from "react";
import "./Contacts.css";

// import api_calls from "./api_calls"; // Import the elizabot module

function App({ setAddContacts, api_calls, token, fetchContacts }) {
  async function name() {
    console.log("add cccccccccc");

    var company_name_input =
      document.getElementById("company_name_input").value;

    var employee_name_input = document.getElementById(
      "employee_name_input"
    ).value;

    var phone_input = document.getElementById("phone_input").value;

    var lan_line_input = document.getElementById("lan_line_input").value;
    var note_input = document.getElementById("note_input").value;
    try {
      const response = await api_calls.add_contacts(
        token,
        company_name_input,
        employee_name_input,
        phone_input,
        lan_line_input,
        note_input
      );
      // console.log(response.data.add_contacts.content);
      if (response.data.add_contacts.content === "good") {
        setAddContacts(false);
        fetchContacts();
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  }

  function meme() {
    setAddContacts(false);
  }

  return (
    <div id="add_contander_mobile">
      <div id="add_contander_input_mobile">
        <div>
          <p>company_name</p>
          <input id="company_name_input" type="text" />
        </div>

        <div>
          <p>employee_name</p>
          <input id="employee_name_input" type="text" />
        </div>

        <div>
          <p>phone</p>
          <input id="phone_input" type="text" />
        </div>

        <div>
          <p>lan_line</p>
          <input id="lan_line_input" type="text" />
        </div>
      </div>
      <div>
        <p>note</p>
        <textarea id="note_input" name="" cols="30" rows="4"></textarea>
      </div>
      <button onClick={name}>add Contacts</button>
      <button onClick={meme}>cancel</button>
    </div>
  );
}

export default App;
