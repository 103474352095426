import React, { useState, useEffect, useCallback } from "react";
import "./Bookmarks.css";

import Toolbar from "./toolbar/Toolbar";
import AddBookmarkCard from "./add_bookmark_card/Bookmarks";

import api_calls from "./api_calls";
const Main = () => {
  console.log("mobile bookmark");
  const token = JSON.parse(localStorage.getItem("usr_tok"));

  const [ListOfBookmarks, setListOfBookmarks] = useState([]);
  const [ShoeAddBookmark, setShoeAddBookmark] = useState("hide"); //shoe

  const [ShoeEditBookmarkMod, setEditBookmarkMod] = useState(0);

  function render_bookmarks(skdjfgyy) {
    var lIst = JSON.parse(skdjfgyy.data.get_bookmarks.folder_content).bookmarks;
    console.log(lIst);

    var arry = [];

    for (let index = 0; index < lIst.length; index++) {
      arry.push(JSON.parse(decodeURIComponent(lIst[index])));
    }

    setListOfBookmarks(arry);
  }

  const fetchData = useCallback(async () => {
    try {
      const data = await api_calls.get_bookmarks(token, 0);
      // console.log(data);
      render_bookmarks(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function cancel_onPress() {
    document.getElementById("mobile_homepage_title_text_input").value = "";
    document.getElementById("mobile_homepage_url_text_input").value = "";

    setShoeAddBookmark("hide");
  }
  function ShoeAddBookmark_onClick() {
    setShoeAddBookmark("shoe");
  }

  function name(params) {
    console.log(params);
    if (params === "good") {
      cancel_onPress();
      fetchData();
    }
  }
  function addAddBookmark_onClick() {
    var title = document.getElementById(
      "mobile_homepage_title_text_input"
    ).value;
    var url = document.getElementById("mobile_homepage_url_text_input").value;

    if (url !== "") {
      if (title === "") {
        title = "title";
      }
      api_calls
        .set_bookmark(token, title, url)
        .then((data) => name(data.data.set_bookmarks.content));
    }
  }
  function set_edit_mode() {
    setEditBookmarkMod(ShoeEditBookmarkMod === 0 ? 1 : 0);
  }

  function delete_bookmark_onClick(token, url, title) {
    api_calls.delete_bookmark(token, url, title);
    fetchData();
  }
  return (
    <div id="homepage_bookmarks">
      <Toolbar
        setShoeAddBookmarkOnClick={ShoeAddBookmark_onClick}
        set_edit_mode={set_edit_mode}
      />

      <div id={"homepage_add_bookmarks_container_" + ShoeAddBookmark}>
        <AddBookmarkCard
          addAddBookmark_onClick={addAddBookmark_onClick}
          cancel_onPress={cancel_onPress}
        />
      </div>

      <div id="home_link_holder">
        {ListOfBookmarks.map((number, index) => {
          if (ShoeEditBookmarkMod === 0) {
            return (
              <a
                href={decodeURIComponent(number.url)}
                className="home_link_card_container"
              >
                <div className="sdfkjgfdg">
                  <div className="home_link_card"></div>
                </div>
                <p>{decodeURIComponent(number.title)}</p>
              </a>
            );
          } else {
            return (
              <div className="home_link_card_container">
                <div className="sdfkjgfdg">
                  <div className="home_link_card">
                    <div
                      className="homepage_bookmarks_delete_icon"
                      onClick={() =>
                        delete_bookmark_onClick(token, number.url, number.title)
                      }
                    >
                      <p>🗑️</p>
                    </div>
                  </div>
                </div>
                <p>{decodeURIComponent(number.title)}</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Main;
