import React from "react";
import "./Toolbar.css";

const Toolbar = ({ setShoeAddBookmarkOnClick, set_edit_mode }) => {
  return (
    <div id="homepage_toolbar">
      {/* this is the back button */}
      {/* <div id="homepage_toolbar_delete_button">
        <p>⇦</p>
      </div> */}

      {/* this is the folder name */}
      {/* <div id="homepage_toolbar_directory_name">
        <p>directory_name </p>
      </div> */}

      <div id="homepage_toolbar_edit_button" onClick={set_edit_mode}>
        <p>edit</p>
      </div>

      <div id="homepage_toolbar_add_button" onClick={setShoeAddBookmarkOnClick}>
        <p>+</p>
      </div>
    </div>
  );
};

export default Toolbar;
