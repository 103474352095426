async function get_contacts(token) {
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
  query lskdfh($token: String) {
    get_contacts(token: $token) {
        content
    }
}
  `;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
      },
    }),
  };

  const result = await fetch(url, options);
  return await result.json();
}

async function add_contacts(
  token,
  company_name,
  employee_name,
  phone,
  lan_line,
  note
) {
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
  query lskdfh($token: String, $company_name: String, $employee_name: String, $phone: String, $lan_line: String, $note: String) {
    add_contacts(token: $token, company_name: $company_name, employee_name: $employee_name, phone: $phone, lan_line: $lan_line, note: $note) {
        content
    }
}
  `;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
        company_name: company_name,
        employee_name: employee_name,
        phone: phone,
        lan_line: lan_line,
        note: note,
      },
    }),
  };

  const result = await fetch(url, options);
  return await result.json();
}

async function delete_contacts(
  token,
  company_id,
  company_name,
  employee_name,
  phone,
  lan_line,
  note
) {
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
  query lskdfh($token: String, $company_id: String, $company_name: String, $employee_name: String, $phone: String, $lan_line: String, $note: String) {
    delete_contacts(token: $token, company_id: $company_id, company_name: $company_name, employee_name: $employee_name, phone: $phone, lan_line: $lan_line, note: $note) {
        content
    }
}
  `;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
        company_id: company_id,
        company_name: company_name,
        employee_name: employee_name,
        phone: phone,
        lan_line: lan_line,
        note: note,
      },
    }),
  };

  const result = await fetch(url, options);
  return await result.json();
}

module.exports = {
  get_contacts,
  add_contacts,
  delete_contacts,
};
