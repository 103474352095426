async function get_note_body_and_title(token, iidd) {
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
    query lskdfh($token: String, $id: String) {
      get_note_body_and_title(token: $token, id: $id) {
        body
        title
      }
    }
    `;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
        id: iidd,
      },
    }),
  };
  const result = await fetch(url, options);
  return await result.json();
}

async function get_note_titles(token) {
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
    query lskdfh($token: String) {
      get_note_titles(token: $token) {
        title,
        id
      }
    }
  `;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
      },
    }),
  };
  const result = await fetch(url, options);
  return await result.json();
}

async function update_note_text(user_token, id, body) {
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
  query lskdfh($token: String, $id: String, $title: String, $body: String) {
    update_note(token: $token, id: $id, title: $title, body: $body) {
      token
    }
  }
`;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: user_token,
        id: id,
        body: body,
      },
    }),
  };
  const result = await fetch(url, options);
  return await result.json();
}

async function note_add_button(user_token, title, body) {
  // console.log("note_add_button");

  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
  query lskdfh($token: String, $title: String, $body: String) {
    set_note(token: $token, title: $title, body: $body) {
      token
    }
  }
`;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: user_token,
        title: title,
        body: body,
      },
    }),
  };
  const result = await fetch(url, options);
  return await result.json();
}

async function delete_note(token, id) {
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
    query lskdfh($token: String, $id: String) {
      remove_note(token: $token, id: $id) {
        token
      }
    }
  `;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
        id: id,
      },
    }),
  };
  const result = await fetch(url, options);
  return await result.json();
}

async function update_note_title(token, id, title) {
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
  query lskdfh($token: String, $id: String, $title: String) {
    update_note(token: $token, id: $id, title: $title) {
      token
    }
  }
  `;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
        id: id,
        title: title,
      },
    }),
  };
  // await fetch(url, options);
  const result = await fetch(url, options);
  return await result.json();
}

module.exports = {
  get_note_titles,
  get_note_body_and_title,
  update_note_text,
  note_add_button,
  delete_note,
  update_note_title,
};
