import React, { useState, useEffect, useCallback } from "react";
import "./Bookmarks.css";

import Toolbar from "./toolbar/Toolbar";
import AddBookmarkCard from "./add_bookmark_card/Bookmarks";

const Main = () => {
  console.log("api desktop bookmark");
  const token = JSON.parse(localStorage.getItem("usr_tok"));
  const id = 0;

  const [ListOfBookmarks, setListOfBookmarks] = useState([]);
  const [ShoeAddBookmark, setShoeAddBookmark] = useState("hide"); //shoe

  const [ShoeEditBookmarkMod, setEditBookmarkMod] = useState(0);

  const get_bookmarks = useCallback(async () => {
    console.log("api desktop get_bookmarks");
    const url = "https://api.megacomus.com/graphql";

    const GET_FILMS = `
    query lskdfh($token: String, $id: Int) {
      get_bookmarks(token: $token, id: $id) {
        folder_content
      }
    }
  `;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: GET_FILMS,
        variables: {
          token: token,
          id: id,
        },
      }),
    };

    const result = await fetch(url, options);
    return await result.json();
  }, [token, id]); // Include token and id as dependencies

  function render_bookmarks(skdjfgyy) {
    // console.log(JSON.parse(skdjfgyy.data.get_bookmarks.folder_content).bookmarks);
    var lIst = JSON.parse(skdjfgyy.data.get_bookmarks.folder_content).bookmarks;

    var arry = [];

    for (let index = 0; index < lIst.length; index++) {
      arry.push(JSON.parse(decodeURIComponent(lIst[index])));
    }

    setListOfBookmarks(arry);
  }

  const fetchData = useCallback(async () => {
    console.log("fetchData");
    const data = await get_bookmarks();
    console.log(data);
    render_bookmarks(data);
  }, [get_bookmarks]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function cancel_onPress() {
    document.getElementById("homepage_title_text_input").value = "";
    document.getElementById("homepage_url_text_input").value = "";

    setShoeAddBookmark("hide");
  }
  function ShoeAddBookmark_onClick() {
    setShoeAddBookmark("shoe");
  }

  async function set_bookmark(title, url) {
    const api = "https://api.megacomus.com/graphql";

    const GET_FILMS = `
    query lskdfh($token: String, $url: String, $title: String) {
      set_bookmarks(token: $token, title: $title, url: $url) {
        content
      }
    }
  `;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: GET_FILMS,
        variables: {
          token: token,
          title: title,
          url: url,
        },
      }),
    };

    const result = await fetch(api, options);
    return await result.json();
  }
  function name(params) {
    if (params === "good") {
      cancel_onPress();
      get_bookmarks().then((data) => render_bookmarks(data));
    }
  }
  function addAddBookmark_onClick() {
    var title = document.getElementById("homepage_title_text_input").value;
    var url = document.getElementById("homepage_url_text_input").value;

    if (url !== "") {
      if (title === "") {
        title = "title";
      }
      set_bookmark(title, url).then((data) =>
        name(data.data.set_bookmarks.content)
      );
    }
  }
  function set_edit_mode() {
    setEditBookmarkMod(ShoeEditBookmarkMod === 0 ? 1 : 0);
  }
  function delete_bookmark(link, title) {
    const url = "https://api.megacomus.com/graphql";

    const GET_FILMS = `
      query lskdfh($token: String, $title: String, $link: String) {
        delete_bookmark(token: $token, title: $title, link: $link) {
          content
        }
      }
    `;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: GET_FILMS,
        variables: {
          token: token,
          title: decodeURIComponent(title),
          link: decodeURIComponent(link),
        },
      }),
    };

    // const result = await fetch(url, options);
    // return await result.json();
    fetch(url, options);
    get_bookmarks().then((data) => render_bookmarks(data));
  }

  return (
    <div id="homepage_bookmarks">
      <Toolbar
        setShoeAddBookmarkOnClick={ShoeAddBookmark_onClick}
        set_edit_mode={set_edit_mode}
      />

      <div id={"homepage_add_bookmarks_container_" + ShoeAddBookmark}>
        <AddBookmarkCard
          addAddBookmark_onClick={addAddBookmark_onClick}
          cancel_onPress={cancel_onPress}
        />
      </div>

      <div id="home_link_holder">
        {ListOfBookmarks.map((number, index) => {
          if (ShoeEditBookmarkMod === 0) {
            return (
              <a
                href={decodeURIComponent(number.url)}
                className="home_link_card_container"
              >
                <div className="sdfkjgfdg">
                  <div className="home_link_card"></div>
                </div>
                <p>{decodeURIComponent(number.title)}</p>
              </a>
            );
          } else {
            return (
              <div className="home_link_card_container">
                <div className="sdfkjgfdg">
                  <div className="home_link_card">
                    <div
                      className="homepage_bookmarks_delete_icon"
                      onClick={() => delete_bookmark(number.url, number.title)}
                    >
                      <p>🗑️</p>
                    </div>
                  </div>
                </div>
                <p>{decodeURIComponent(number.title)}</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Main;
