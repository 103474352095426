import React from "react";
import "./todo_column.css";

import TodoCard from "./todo_card/todo_card";

const Main = ({ todo_list, delete_function, position, add_todo }) => {
  var placeholder = "position.toString()";
  if (position === 0) {
    placeholder = "hig";
  }
  if (position === 1) {
    placeholder = "mid";
  }
  if (position === 2) {
    placeholder = "low";
  }

  // console.log(todo_list);
  // console.log(todo_list.length);

  if (todo_list.length !== 0) {
    todo_list = decodeURIComponent(todo_list);
    todo_list = todo_list.split(",");
  } else {
    todo_list = [];
  }

  return (
    <div className="todo_contander">
      <div className="todo_top_bar_todo">
        <input
          id={"todo_text_input_" + position}
          type="text"
          placeholder={placeholder}
          className="styled_input_todo"
          // autoComplete="off"
        />
        <button
          className="todo_add_todo_button"
          onClick={() => add_todo(position)}
        >
          +
        </button>
      </div>
      <div>
        {todo_list.map((number, index) => (
          <div className="todo_card">
            <TodoCard
              todo={number}
              index={index}
              delete_function={delete_function}
              position={position}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Main;
