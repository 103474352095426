import "./App.css";

import Card from "./card/App";

import CustomerService from "../Svg/247-customer-service.png";
import Todo from "../Svg/todo.png";
import Note from "../Svg/note.png";
import Contacts from "../Svg/contacts.png";

function App() {
  return (
    <div className="grey-card">
      <div id="two-card-title">
        <h1>About Megacomus</h1>
      </div>

      <div id="two-card-main-card-contander">
        <Card
          title={"24/7 customer service"}
          svg={CustomerService}
          disicein={`Our dedicated team is available around the clock to assist you with any inquiries or support needs. Whether you have questions about our products or need assistance with an order, we're here to help you anytime, day or night`}
        />
        <Card
          title={"todo"}
          svg={Todo}
          disicein={
            "Stay organized and on top of your tasks with our interactive to-do list feature. Easily create, and prioritize tasks to ensure you never miss a deadline or forget an important item on your agenda. Streamline your workflow and boost productivity with our intuitive to-do list tool"
          }
        />
        <Card
          title={"note"}
          svg={Note}
          disicein={
            "Capture your thoughts, ideas, and reminders with our convenient note-taking feature. Whether you need to jot down meeting minutes, brainstorm new concepts, or simply make a quick memo, our note-taking tool provides a seamless way to keep track of important information and stay organized"
          }
        />
        <Card
          title={"contacts"}
          svg={Contacts}
          disicein={
            "Connect with ease using our comprehensive contacts feature. Access essential contact information for colleagues, clients, and partners, and stay in touch effortlessly. With our contacts tool, you can efficiently manage your network and communicate effectively with just a few clicks"
          }
        />
      </div>
    </div>
  );
}

export default App;
