import "./group_chat_message.css";

import message_time from "./get_time";

function App({ user, time_sent, message }) {
  return (
    <div className="messages">
      <div className="profile_picture"></div>

      <div>
        <div className="messages_header">
          {/* <a href={user}>{user}</a> */}
          <p>{user}</p>
          <p className="time_stamp">{message_time.get_time_since(time_sent)}</p>
        </div>
        <div className="messages_text">{message}</div>
      </div>
    </div>
  );
}

export default App;
