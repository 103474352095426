
import "./Hmoepag_not_login.css";

import TopBar from "./top_bar/App";
import OneCard from "./one_card/App";
import TwoCard from "./two_card/App";

import BottonCard from "./botton_card/App";

function App() {
  return (
    <div id="App-home-page">
      <div id="home-window">
        <TopBar />
        <OneCard />
        <TwoCard />
        <BottonCard />
      </div>
    </div>
  );
}

export default App;
