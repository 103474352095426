import React, { useEffect, useState } from "react";
import "./Note.css";
import AddNote from "./add_note/add_note";
import TitleCatd from "./title_catd/title_catd";
import NoteBody from "./note_body/note_body";
import NoteTopCard from "./note_top_card/note_top_card";
import api_calls from "./api_calls"; // Import the elizabot module

let note_time_to_save_body_txt;
let note_time_to_save_title;

const Main = () => {
  const token = JSON.parse(localStorage.getItem("usr_tok"));

  const [list_of_titles_and_ids, set_list_of_titles_and_ids] = useState({});
  const [title, set_title] = useState("");
  const [note_body, set_note_body] = useState("");
  const [note_id, set_note_id] = useState("");
  const [note_save_or_not, setnote_save_or_not] = useState(true);

  function render(token) {
    document.getElementById("note_add_text_box").value = "";

    const render_note_titles = async () => {
      try {
        const data = await api_calls.get_note_titles(token);
        set_list_of_titles_and_ids(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    render_note_titles();
  }

  useEffect(() => {
    render(token);
  }, [token]); // empty dependency array to run the effect only once

  async function title_card_on_click(params) {
    document.getElementById("note_body_container").style.display = "flex";

    const data = await api_calls.note_get_note_body(token, params[1]);
    set_note_id(params[1]);
    set_title(data.data.get_note_body_and_title.title);
    set_note_body(data.data.get_note_body_and_title.body);

    document.getElementById("note_add_text_box").value = "";
  }

  async function save_note(params) {
    setnote_save_or_not(false);

    clearTimeout(note_time_to_save_body_txt);
    note_time_to_save_body_txt = setTimeout(async () => {
      try {
        const data = await api_calls.update_note_text(token, note_id, params);
        if (data.data.update_note.token === "s") {
          setnote_save_or_not(true);
        } else {
          throw new Error("This is an example error.");
        }
      } catch (error) {
        console.error("Error saving note:", error);
      }
    }, 300);
  }

  function save_note_title(params) {
    setnote_save_or_not(false);

    clearTimeout(note_time_to_save_title);
    note_time_to_save_title = setTimeout(async () => {
      // console.log(params);
      // update_note_title
      const data = await api_calls.update_note_title(token, note_id, params);
      if (data.data.update_note.token === "s") {
        setnote_save_or_not(true);
        render(token);
      }
    }, 300);
  }

  async function make_new_note(title, body) {
    const data = await api_calls.note_add_button(token, title, body);
    if (data.data.set_note.token === "s") {
      // console.log("new note");
      render(token);
    }
  }

  async function delete_note() {
    // console.log(note_id);

    document.getElementById("note_body_container").style.display = "none";

    const data = await api_calls.delete_note(token, note_id);
    if (data.data.remove_note.token === "s") {
      set_note_id("");
      set_title("");
      set_note_body("");

      render(token);
    }
  }
  return (
    <div id="note_main">
      <div id="note_title_container">
        <AddNote make_new_note={make_new_note} />
        <TitleCatd
          list_of_titles_and_ids={list_of_titles_and_ids}
          title_card_on_click={title_card_on_click}
        />
      </div>
      <div id="note_body_container">
        <NoteTopCard
          title={title}
          note_save_or_not={note_save_or_not}
          delete_note={delete_note}
          save_note_title={save_note_title}
        />
        <NoteBody note_body={note_body} save_note={save_note} />
      </div>
    </div>
  );
};

export default Main;
