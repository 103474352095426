import React from "react";
import "./App.css";

import Clock from "./Clock/Clock";
import Search from "./search/Search";
import Bookmarks from "./bookmarks/Bookmarks";

function App() {
  console.log("home");
  return (
    <div id="mobile_homepage">
      <Clock />
      <Search />
      <Bookmarks />
    </div>

    // <div classname="container">
    //   <header classname="top-bar">Top Bar</header>
    //   <main classname="content">Your main content goes here</main>
    //   <footer classname="bottom-bar">Bottom Bar</footer>
    // </div>
  );
}

export default App;
