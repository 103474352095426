async function get_bookmarks(token, id) {
  console.log("api mobile get_bookmarks");
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
  query lskdfh($token: String, $id: Int) {
    get_bookmarks(token: $token, id: $id) {
      folder_content
    }
  }
`;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
        id: id,
      },
    }),
  };

  const result = await fetch(url, options);
  return await result.json();
}

function delete_bookmark(token, link, title) {
  const url = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
    query lskdfh($token: String, $title: String, $link: String) {
      delete_bookmark(token: $token, title: $title, link: $link) {
        content
      }
    }
  `;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
        title: decodeURIComponent(title),
        link: decodeURIComponent(link),
      },
    }),
  };

  fetch(url, options);
}

async function set_bookmark(token, title, url) {
  const api = "https://api.megacomus.com/graphql";

  const GET_FILMS = `
  query lskdfh($token: String, $url: String, $title: String) {
    set_bookmarks(token: $token, title: $title, url: $url) {
      content
    }
  }
`;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_FILMS,
      variables: {
        token: token,
        title: title,
        url: url,
      },
    }),
  };

  const result = await fetch(api, options);
  return await result.json();
}
module.exports = {
  get_bookmarks,
  delete_bookmark,
  set_bookmark,
};
