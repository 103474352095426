import React, { useState } from "react";
import "./App.css";

import Menu from "./menu/App";
import Home from "./homepage/App";
import Contacts from "./contacts/Contacts";
import Note from "./note/App";
import Todo from "./todo/App";
import Settings from "./settings/App";

function App() {
  const [showMenu, setMenu] = useState(false);
  const [showHome, setHome] = useState(true);
  const [showChat, setChat] = useState(false);
  const [showTodo, setTodo] = useState(false);
  const [showContacts, setContacts] = useState(false);
  const [showNote, setNote] = useState(false);
  const [showSettings, setSettings] = useState(false);

  const resetStates = (exceptState) => {
    setMenu(exceptState === "menu");
    setHome(exceptState === "home");
    setChat(exceptState === "chat");
    setTodo(exceptState === "todo");
    setNote(exceptState === "note");
    setSettings(exceptState === "settings");
    setContacts(exceptState === "contacts");
  };

  function hamburger_menu_oncloick() {
    resetStates("menu");
  }

  var start_theme = "mobile_lite";

  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    start_theme = "mobile_datk";
  }

  const [theme, settheme] = useState(start_theme);

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      const newColorScheme = event.matches ? "mobile_datk" : "mobile_lite";
      settheme(newColorScheme);
    });
  return (
    <div className={theme + "_container"}>
      <header className="top-bar">
        <div onClick={hamburger_menu_oncloick}>
          <h1>☰</h1>
        </div>

        <div id="mobile_top_card_name">
          {showMenu ? <h2>megacomus • Menu</h2> : null}
          {showHome ? <h2>megacomus • Home</h2> : null}
          {showChat ? <h2>megacomus • chat</h2> : null}
          {showNote ? <h2>megacomus • Note</h2> : null}
          {showTodo ? <h2>megacomus • Todo</h2> : null}
          {showSettings ? <h2>megacomus • Settings</h2> : null}
          {showContacts ? <h2>megacomus • contacts</h2> : null}
        </div>
      </header>
      <main className={theme + "_content"}>
        {showMenu ? <Menu resetStates={resetStates} /> : null}
        {showHome ? <Home /> : null}
        {showContacts ? <Contacts /> : null}
        {showChat ? <Note /> : null}
        {showNote ? <Note /> : null}
        {showTodo ? <Todo /> : null}
        {showSettings ? <Settings /> : null}
      </main>
    </div>
  );
}

export default App;
