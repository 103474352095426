// import App from "./app/App";
import App from "./desktop_and_mobile/App";
import Login from "./login/Hmoepag_not_logind";

const userAuth = () => {
  const uer_tokin = localStorage.getItem("usr_tok");

  if (uer_tokin) {
    return true;
  } else {
    return false;
  }
};

const Main = () => {
  console.log("Auth");

  const isAuth = userAuth();

  // return isAuth ? <App /> : <Login />;
  return isAuth ? <App /> : <Login />;
};

export default Main;
