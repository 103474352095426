import React from "react";
import "./todo_card.css";

const Main = ({ todo, index, delete_function, position }) => {
  return (
    <div className="todo_card">
      <p>{todo}</p>
      <p onClick={() => delete_function([position, index])}>X</p>
    </div>
  );
};
export default Main;
