import { Link } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div id="top-bar">
      <div id="sfdfsdfsdf">
        <h1>MEGACOMUS</h1>
      </div>
      <div id="top-bar-links">
        <div className="top-bar-link">
          <Link to="/app">
            <p>login</p>
          </Link>
        </div>
        <div className="top-bar-link">
          <Link to="/sineup">
            <p>Sineup</p>
          </Link>
        </div>
        <div className="top-bar-link">
          <Link to="/contactup">
            <p>Contact Us</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
