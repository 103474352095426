import React, { useEffect } from "react";
// import React from "react";
import "./note_top_card.css";

const Main = ({ title, note_save_or_not, delete_note, save_note_title }) => {
  useEffect(() => {
    document.getElementById("note_title_textbox").value =
      decodeURIComponent(title);
  }, [title]);

  function update_note_text() {
    // clearTimeout(time_to_save_title);
    // time_to_save_title = setTimeout(async () => {
    //   console.log(document.getElementById("note_title_textbox").value);
    //   console.log(note_id);
    // }, 1500);
    var title_txt = document.getElementById("note_title_textbox").value;
    save_note_title(title_txt);
  }
  return (
    <div id="note_body_top_bar">
      <input type="text" id="note_title_textbox" onChange={update_note_text} />
      <button id="note_delete_button" onClick={delete_note}>
        delete
      </button>

      <div id="noet_saved_and_spinner">
        {note_save_or_not ? <p>saved</p> : <div className="spinner"></div>}
      </div>
    </div>
  );
};

export default Main;
