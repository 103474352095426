import React, { useState } from "react";
import "./Search.css";
import "./Search_input.css";

const Main = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchEngines, setSearchEngines] = useState({
    google: true,
    brave: false,
    yahoo: false,
    bing: false,
    duckduckgo: false,
  });

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchEngineToggle = (engine) => {
    setSearchEngines((prevEngines) => ({
      ...prevEngines,
      [engine]: !prevEngines[engine],
    }));
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      const linkArray = [];

      if (searchEngines.bing) {
        linkArray.push(`https://www.bing.com/search?q=${searchTerm}`);
      }
      if (searchEngines.duckduckgo) {
        linkArray.push(`https://duckduckgo.com/?q=${searchTerm}`);
      }
      if (searchEngines.yahoo) {
        linkArray.push(`https://search.yahoo.com/search?p=${searchTerm}`);
      }
      if (searchEngines.google) {
        linkArray.push(`https://www.google.com/search?q=${searchTerm}`);
      }
      if (searchEngines.brave) {
        linkArray.push(
          `https://search.brave.com/search?q=${searchTerm}&source=desktop`
        );
      }

      linkArray.forEach((link) => {
        window.open(link, "_blank");
      });
    }
  };

  return (
    <div id="search">
 
      <div id="Search_input">
        {/* <p id="Search_input_icon">🖼️</p> */}
        <input
          type="text"
          placeholder="search"
          className="styled-input"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleSearch}
        />
      </div>

      <div id="search_one">
        {Object.keys(searchEngines).map((engine) => (
          <div key={engine} className="search_one_engine">
            <input
              type="checkbox"
              id={`search_switch_${engine}`}
              checked={searchEngines[engine]}
              onChange={() => handleSearchEngineToggle(engine)}
            />
            <p>{engine}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Main;
