import React from "react";
import "./Bookmarks.css";

const Main = ({ addAddBookmark_onClick, cancel_onPress }) => {
 

  return (
    <div id="homepage_add_bookmarks_card">
      <input
        id="mobile_homepage_url_text_input"
        type="text"
        placeholder="url"
        className="homepage_add_bookmarks_card_textinput"
        autoComplete="off"
      />
      <input
        id="mobile_homepage_title_text_input"
        type="text"
        placeholder="title"
        className="homepage_add_bookmarks_card_textinput"
        autoComplete="off"
      />
      <div id="homepage_cancel_and_add_button_container">
        <button onClick={cancel_onPress}>cancel</button>
        <button onClick={addAddBookmark_onClick}>add bookmark</button>
      </div>
    </div>
  );
};

export default Main;
