import React, { useState, useEffect, useCallback } from "react";
import "./App.css";

import api_calls from "./api_calls";

var mobileTimeToNextTimer;
var selected_note_id = "";

function App() {
  console.log("note");
  const token = JSON.parse(localStorage.getItem("usr_tok"));
  const [ListOfNotes, setListOfNotes] = useState({
    title: [],
    id: [],
  });

  var [titleOrBody, setTitleOrBody] = useState(true);
  var [note, setNote] = useState({ title: "", body: "test" });
  var [shoeSpinner, setshoeSpinner] = useState(false);

  // Wrap the fetchData function in useCallback
  const fetchData = useCallback(async () => {
    console.log("fetchData");
    try {
      const data = await api_calls.get_note_titles(token);

      if (data.data.get_note_titles.id.length !== 0) {
        var list_of_notes = data.data.get_note_titles;
        list_of_notes.id = list_of_notes.id.split(",");
        list_of_notes.title = list_of_notes.title.split(",");

        setListOfNotes(list_of_notes);
      } else {
        setListOfNotes({
          title: [],
          id: [],
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [token]); // Add token as a dependency to useCallback

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function mobile_title_onClick(params) {
    const data = await api_calls.get_note_body_and_title(token, params);
    console.log(data.data.get_note_body_and_title);
    selected_note_id = params;
    setNote(data.data.get_note_body_and_title);

    setTitleOrBody(false);
  }

  function back() {
    setTitleOrBody(true);
  }

  function mobile_body_update(params) {
    setshoeSpinner(true);

    clearTimeout(mobileTimeToNextTimer);
    mobileTimeToNextTimer = setTimeout(async () => {
      console.log("save body");
      console.log(params);

      const data = await api_calls.update_note_text(
        token,
        selected_note_id,
        params
      );
      console.log(data);
      if (data.data.update_note.token === "s") {
        setshoeSpinner(false);
      } else {
        throw new Error("This is an example error.");
      }
    }, 600);
  }

  async function add_note_onClick() {
    console.log("add_note_onClick");

    var mobileNewNoteTitle = document.getElementById(
      "mobile_new_note_title"
    ).value;

    if (mobileNewNoteTitle) {
      console.log(mobileNewNoteTitle);

      document.getElementById("mobile_new_note_title").value = "";

      const data = await api_calls.note_add_button(
        token,
        mobileNewNoteTitle.toString(),
        ""
      );
      if (data.data.set_note.token === "s") {
        console.log("new note");
        fetchData();
      }
    }
  }

  async function mobile_delete_note() {
    const data = await api_calls.delete_note(token, selected_note_id);
    if (data.data.remove_note.token === "s") {
      // todo run fetchData() and setTitleOrBody(true)
      await fetchData(); // Wait for fetchData to complete
      setTitleOrBody(true); // Switch to the title view
    }
  }

  function mobile_title_update(jkdfshfjg) {
    setshoeSpinner(true);
    clearTimeout(mobileTimeToNextTimer);
    mobileTimeToNextTimer = setTimeout(async () => {
      console.log("save title");
      console.log(jkdfshfjg);

      const data = await api_calls.update_note_title(
        token,
        selected_note_id,
        jkdfshfjg
      );
      if (data.data.update_note.token === "s") {
        fetchData();
        setshoeSpinner(false);
      }

      // setshoeSpinner(false);
    }, 600);
  }
  return (
    <div>
      {titleOrBody ? (
        <div id="mobile_list_of_title">
          <div id="mobile_add_note">
            <input
              id="mobile_new_note_title"
              type="text"
              placeholder="add new note here"
            />
            <button onClick={add_note_onClick}>+</button>
          </div>

          <h1>list of note's</h1>
          <p>note</p>

          {ListOfNotes.id.map(function (data, index) {
            return (
              <div
                key={index}
                className="mobile_note_title_card"
                onClick={() => mobile_title_onClick(data)}
              >
                <p> {decodeURIComponent(ListOfNotes.title[index])}</p>
              </div>
            );
          })}
        </div>
      ) : null}

      {titleOrBody ? null : (
        <div id="mobile_note">
          <div id="mobile_titlea_and_backButton">
            <button onClick={back}>back</button>
            <input
              type="text"
              id="mobile_title_input"
              defaultValue={decodeURIComponent(note.title)}
              // onChange={mobile_title_update}
              onInput={(e) => mobile_title_update(e.currentTarget.value)}
            />
            {shoeSpinner ? null : (
              <button onClick={mobile_delete_note}>delete</button>
            )}

            {shoeSpinner ? <div id="mobile_spinner"></div> : null}
          </div>

          <textarea
            id="mobile_body_input"
            onInput={(e) => mobile_body_update(e.currentTarget.value)}
            defaultValue={decodeURIComponent(note.body)}
          >
            {/* {decodeURIComponent(note.body)} */}
          </textarea>
        </div>
      )}
    </div>
  );
}

export default App;
