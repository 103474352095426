import React from "react";
import "./Home.css";

import Clock from "./Clock/Clock";
import Search from "./search/Search";
import Bookmarks from "./bookmarks/Bookmarks";

const Main = () => {
  return (
    <div id="homepage_app">
      {/* <button onClick={sineup}>sinup</button>
      <button onClick={login_}>login</button> */}
      <div id="sldhkfhyu">
        <div>
          <Clock id="clock_fase" />

          <Search />
        </div>
      </div>
      <hr />
      <br />
      <Bookmarks />
    </div>
  );
};

export default Main;
