import React, { useState } from "react";
import "./ErrPage.css";

function App() {
  var start_theme = "homepage_lite";

  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    start_theme = "homepage_datk";
  }

  const [theme, settheme] = useState(start_theme);

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      const newColorScheme = event.matches ? "homepage_datk" : "homepage_lite";
      settheme(newColorScheme);
    });

  return (
    <div id={theme}>
      <p>404 page not fond</p>
    </div>
  );
}

export default App;
