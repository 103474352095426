import React from "react";
import "./App.css";

function App() {
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      <p>settings</p>
      <button id="mobile_logout_button" onClick={logout}>
        logout
      </button>
    </div>
  );
}

export default App;
