import Desktop from "./desktop_app/App";
import Mobile from "./mobile/App";

import React, { useEffect, useState } from "react";

const MyComponent = () => {
  const [showMobile, setMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 700) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 700) {
      setMobile(false);
    } else {
      setMobile(true);
    }

    // Add event listener when component mounts
    window.addEventListener("resize", handleResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  // Your rendering logic here, and you can use `windowWidth` in your component

  return (
    <div>
      {showMobile ? (
        <div id="mobile_main_contander">
          <Mobile />
        </div>
      ) : null}

      {showMobile ? null : (
        <div id="desktop_main_contander">
          <Desktop />
        </div>
      )}
    </div>
  );
};

export default MyComponent;
