import React from "react";
import "./settings.css";

const Main = () => {
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div id="settings_page">
      <h1>settings</h1>
      <button onClick={logout} id="logout_button">logout</button>
    </div>
  );
};
export default Main;
