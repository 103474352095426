import "./sineup.css";

function App() {
  return (
    <div id="contactup">
      <div id="contactup-two">
        <h1>Contact Us</h1>
        <br />
        <p>
          Have a question, comment, or inquiry? We'd love to hear from you! Drop
          us an email at contactus@megacomus.com and we'll get back to you as
          soon as possible. Your feedback is valuable to us as we strive to
          provide the best experience for our visitors.
        </p>
        <br />
        <p>Thank you for reaching out!</p>
      </div>
    </div>
  );
}

export default App;
