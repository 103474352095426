import React, { useState, useEffect, useCallback } from "react";
import "./Contacts.css";

import ContactCard from "./contact_card/Contacts";
import TopBar from "./top_bar/Contacts";
import AddContactPage from "./add_contact_page/Contacts";

import api_calls from "./api_calls"; // Import the elizabot module

function App() {
  const [showListOfContacts, setListOfContacts] = useState([]);
  const [showAddContacts, setAddContacts] = useState(false);
  const [showIsEdit, setIsEdit] = useState(false);

  console.log("Contacts");
  const token = JSON.parse(localStorage.getItem("usr_tok"));

  const fetchContacts = useCallback(async () => {
    try {
      const response = await api_calls.get_contacts(token);
      var list_of_contacts = JSON.parse(response.data.get_contacts.content);
      console.log(list_of_contacts);
      setListOfContacts(list_of_contacts);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [token]); // Include token in the dependency array

  useEffect(() => {
    fetchContacts();
  }, [token, fetchContacts]);

  return (
    // search_spinner
    <div id="contact_maine_mobile">
      <div id="jdskhgkjfhgkjdshgjkh">
        <TopBar
          setAddContacts={setAddContacts}
          setIsEdit={setIsEdit}
          showIsEdit={showIsEdit}
        />
      </div>

      {showAddContacts ? (
        <AddContactPage
          setAddContacts={setAddContacts}
          api_calls={api_calls}
          token={token}
          fetchContacts={fetchContacts}
        />
      ) : null}

      {false ? (
        <div id="search_spinner_contander">
          <div id="search_spinner"></div>
        </div>
      ) : null}

      {true ? (
        <div id="contactCard_display">
          {showListOfContacts.map((item, index) => (
            <ContactCard
              api_calls={api_calls}
              company_id={decodeURIComponent(item.company_id)}
              company_name={decodeURIComponent(item.company_name)}
              employee_name={decodeURIComponent(item.employee_name)}
              phone={decodeURIComponent(item.phone)}
              lan_line={decodeURIComponent(item.lan_line)}
              note={decodeURIComponent(item.note)}
              key={index}
              showIsEdit={showIsEdit}
              fetchContacts={fetchContacts}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default App;
