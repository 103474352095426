// 

import "./Chat.css";

function App() {
  return (
    <div id="chat_input">
      <p id="chat_input_icon">📎</p>
      <input
        type="text"
        placeholder="Write a message..."
        className="styled-input"
      />
    </div>
  );
}

export default App;
