import React, { useState } from "react";
import "./App.css";

import Chat from "./components/chat/Chat";
import Home from "./components/home/home";
import Settings from "./components/settings/settings";
import Note from "./components/new_notes/note";
import Contacts from "./components/contacts/Contacts";

// import Todo from "./components/old_todos/todo";
import Todo from "./components/todos/todo";

function App() {
  const [showHome, setHome] = useState(true);
  const [showChat, setChat] = useState(false);
  const [showTodo, setTodo] = useState(false);
  const [showNote, setNote] = useState(false);
  const [showSettings, setSettings] = useState(false);
  const [showContacts, setContacts] = useState(false);

  const updateView = (name) => {
    const views = {
      contacts: setContacts,
      chat: setChat,
      home: setHome,
      todo: setTodo,
      note: setNote,
      settings: setSettings,
    };

    Object.keys(views).forEach((view) => {
      views[view](view === name);
    });
  };

  var start_theme = "mein_app_container_lite";

  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    start_theme = "mein_app_container_datk";
  }

  const [theme, settheme] = useState(start_theme);

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      const newColorScheme = event.matches
        ? "mein_app_container_datk"
        : "mein_app_container_lite";
      settheme(newColorScheme);
    });
  const menuItems = ["home", "todo", "note", "contacts", "settings"];

  return (
    <div id={theme}>
      <div id="App">
        <div>
          <div>
            {menuItems.map((item, index) => (
              <div
                key={index}
                className="left_card"
                onClick={() => updateView(item)}
              >
                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>

        {showHome ? <Home /> : null}
        {showTodo ? <Todo /> : null}
        {showNote ? <Note /> : null}
        {showChat ? <Chat /> : null}
        {showSettings ? <Settings /> : null}
        {showContacts ? <Contacts /> : null}
      </div>
    </div>
  );
}

export default App;
