// import React, { useState } from "react";
import React from "react";
import "./add_note.css";

const Main = ({ make_new_note }) => {
  function name() {
    var title = document.getElementById("note_add_text_box").value;

    if (title !== "") {
      document.getElementById("note_add_text_box").value = "";
      make_new_note(title, "");
    } else {
      make_new_note("untitled", "");
    }
  }

  return (
    <div id="note_add_note_box">
      <input type="text" id="note_add_text_box" />
      <button id="add_note_button" onClick={name}>
        +
      </button>
    </div>
  );
};

export default Main;
