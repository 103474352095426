import "./govcon.css";

function App() {
  return (
    <div id="etyirguweuifgh">
      <div id="sfldkhf8u">
        <h1>Important Notice Regarding Our Capability Statements</h1>
        <br />
        <p>
          Due to a recent harassment campaign targeting our previous customers,
          we have made the decision to restrict access to our capability
          statements for government contracting. These documents are now only
          available upon request.
        </p>
        <br />
        <p>
          We deeply regret any inconvenience this may cause, but the safety and
          privacy of our clients and partners are our utmost priority.
        </p>
        <br />
        <p>
          If you require access to our capability statements, please contact us
          directly at info@megacomus.com, and we will be happy to provide them
          to you.
        </p>

        <br />
        <p>Thank you for your understanding and continued support.</p>
      </div>
    </div>
  );
}

export default App;
