import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import HmoepagNotLogin from "./homepage/Hmoepag_not_login";
import Auth from "./Auth/Auth";
import Sineup from "./Auth/sineup/Sineup";
import ContactUp from "./Auth/contact_us/ContactUs";
import Govcon from "./Auth/govcon/Govcon";
import ErrPage from "./ErrPage/ErrPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HmoepagNotLogin />} />
      <Route path="app" element={<Auth />} />
      <Route path="sineup" element={<Sineup />} />
      <Route path="contactup" element={<ContactUp />} />
      <Route path="govcon" element={<Govcon />} />
      <Route path="*" element={<ErrPage />} />
    </Routes>
  </BrowserRouter>
);
