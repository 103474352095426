import React from "react";
import "./App.css";

function App({ todo, delete_function, index, position }) {
  return (
    // <div className="mobile_todo_card">
    //   <p>{todo}</p>
    // </div>
    <div className="mobile_todo_card">
      <p>{decodeURIComponent(todo)}</p>
      <p onClick={() => delete_function([position, index])}>X</p>
    </div>
  );
}

export default App;
