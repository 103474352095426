import React, { useState } from "react";
import "./Hmoepag_not_logind.css";

function App() {
  var start_theme = "loginpage_lite";

  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    start_theme = "loginpage_drak";
  }

  const [theme, settheme] = useState(start_theme);

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      const newColorScheme = event.matches
        ? "loginpage_drak"
        : "loginpage_lite";
      settheme(newColorScheme);
    });

  function save_token(params) {
    console.log(params);
    console.log(typeof params );
    if (params === "wrong") {
      console.log("wrong password");
    } else {
      localStorage.setItem("usr_tok", JSON.stringify(params));
      window.location.reload();
    }
  }
  async function login_to_the_server(user_name, password) {
    const url = "https://api.megacomus.com/graphql";

    const GET_FILMS = `
      query lskdfh($user_name: String, $password: String) {
        login(user_name: $user_name, password: $password) {
          token
        }
      }
      `;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: GET_FILMS,
        variables: {
          user_name: user_name,
          password: password,
        },
      }),
    };

    try {
      const result = await fetch(url, options);
    
      return await result.json();
    } catch (error) {
      console.log("error");
      return await { data: { data: { login: { token: "wrong" } } } };
    }
  }

  async function login_button_press() {
    const user_name = document.getElementById("login_user_name").value;
    const password = document.getElementById("login_password").value;

    if (user_name !== "" && password !== "") {
      document.getElementById("login_password").value = "";
      document.getElementById("login_user_name").value = "";

      login_to_the_server(user_name, password).then(
        (data) => save_token(data.data.login.token)
        // (data) => save_token(data)
        // console.log(data)
      );
    }
  }

  return (
    <div id={theme}>
      <div id="del_786875">
        <input
          id="login_user_name"
          type="text"
          placeholder="user name"
          className="styled_input_todo"
          autoComplete="off"
        />
        <input
          id="login_password"
          type="password"
          placeholder="password"
          className="styled_input_todo"
          autoComplete="off"
        />
        <button id="login_button" onClick={login_button_press}>
          login
        </button>
      </div>
    </div>
  );
}

export default App;
