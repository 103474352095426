import React from "react";
import "./App.css";

function App({ resetStates }) {
  function name(params) {
    console.log(params);
    resetStates(params);
  }
  const menuItems = ["home", "todo", "note", "contacts", "settings"];
  return (
    <div>
      {menuItems.map((item, index) => (
        <div key={index} className="menu_card_icon" onClick={() => name(item)}>
          <p>{item}</p>
        </div>
      ))}
      {/* <div className="menu_card_icon" onClick={() => name("home")}>
        <p>home</p>
      </div>

      <div className="menu_card_icon" onClick={() => name("todo")}>
        <p>todo</p>
      </div>

      <div className="menu_card_icon" onClick={() => name("note")}>
        <p>note</p>
      </div> */}

      {/* <div className="menu_card_icon" onClick={() => name("chat")}>
        <p>chat</p>
      </div> */}

      {/* <div className="menu_card_icon" onClick={() => name("settings")}>
        <p>settings</p>
      </div> */}
    </div>
  );
}

export default App;
