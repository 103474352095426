
import "./App.css";

function App() {
  return (
    <div id="botton-card">
      <p>
        ©2024 megacomus. All rights reserved. Use of this site signifies your
        agreement to the terms of use.
      </p>
      <br />
      {/* <div>
        <a href="govcon">government contracting</a>
      </div> */}
      {/*
      <Link to="/govcon">
        <p>government contracting info</p>
      </Link>*/}

      <br />
      <p>v2</p>
    </div>
  );
}

export default App;
