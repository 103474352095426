import React from "react";
import "./Contacts.css";

function App({
  api_calls,
  company_id,
  company_name,
  employee_name,
  phone,
  lan_line,
  note,
  showIsEdit,
  fetchContacts,
}) {
  const token = JSON.parse(localStorage.getItem("usr_tok"));

  async function contact_delete_button_onClick() {
    console.log("contact_delete_button_onClick");

    try {
      const response = await api_calls.delete_contacts(
        token,
        company_id,
        company_name,
        employee_name,
        phone,
        lan_line,
        note
      );
      console.log(
        token,
        company_id,
        company_name,
        employee_name,
        phone,
        lan_line,
        note
      );
      // console.log(response.data.delete_contacts.content);
      if (response.data.delete_contacts.content === "good") {
        fetchContacts();
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  }
  return (
    <div className="contact_maine_card">
      {showIsEdit ? (
        <button
          onClick={contact_delete_button_onClick}
          className="contact_delete_button"
        >
          <p>🗑️</p>
        </button>
      ) : null}

      <div className="contact_left_display">
        <p>company_name</p>
        <p>employee_name</p>
        <p>phone</p>
        <p>lan_line</p>
        <p>note</p>
        {/* <p>{company_id}</p> */}
        <p>{company_name}</p>
        <p>{employee_name}</p>
        <p>{phone}</p>
        <p>{lan_line}</p>
        {/* <textarea rows="3" className="contact_note_input">{note}</textarea> */}
        {/* <textarea
          rows="1"
          className="contact_note_input"
          defaultValue={note}
        ></textarea> */}
        <p>{note}</p>
      </div>
    </div>
  );
}

export default App;
