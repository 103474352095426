import React, { useState, useEffect, useCallback } from "react";
import "./App.css";
import api_calls from "./api_calls";
import TodoCards from "./todo_card/App";

function App() {
  console.log("todo");
  const [todo_list, set_todo_list] = useState({
    todo_hig: [],
    todo_mid: [],
    todo_low: [],
  });
  const token = JSON.parse(localStorage.getItem("usr_tok"));

  // Wrap the fetchData function in useCallback
  const fetchData = useCallback(async () => {
    try {
      const data = await api_calls.get_todo_from_server(token);
      console.log(data.data.get_todo);
      set_todo_list(data.data.get_todo);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [token]); // Add token as a dependency to useCallback

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function delete_todo(index) {
    console.log(index);
    var text = "";

    if (index[0] === 0) {
      text = todo_list.todo_hig[index[1]];
    }

    if (index[0] === 1) {
      text = todo_list.todo_mid[index[1]];
    }

    if (index[0] === 2) {
      text = todo_list.todo_low[index[1]];
    }

    await api_calls.delete_todo(token, text, index[0]);
    fetchData();
  }

  async function add_todo(index) {
    console.log(index);
    var todo_input_text = document.getElementById(
      "mobile_todo_text_input_" + index
    ).value;
    if (todo_input_text) {
      document.getElementById("mobile_todo_text_input_" + index).value = "";

      await api_calls.set_todo(token, todo_input_text, index);
      // Fetch data again after adding a new todo
      fetchData();
    }
  }

  return (
    <div>
      <div className="mobile_todo_main_card">
        <div className="mobile_todo_input">
          <div className="input-container">
            <input
              type="text"
              className="full-width-input"
              placeholder="hig"
              id="mobile_todo_text_input_0"
            />
          </div>
          <button className="mobile_todo_button" onClick={() => add_todo(0)}>
            +
          </button>
        </div>

        {todo_list.todo_hig.map((item, index) => (
          <TodoCards
            key={item}
            todo={item}
            delete_function={delete_todo}
            position={0}
            index={index}
          />
        ))}
      </div>
      <div className="mobile_todo_main_card">
        <div className="mobile_todo_input">
          <div className="input-container">
            <input
              type="text"
              className="full-width-input"
              placeholder="mid"
              id="mobile_todo_text_input_1"
            />
          </div>
          <button className="mobile_todo_button" onClick={() => add_todo(1)}>
            +
          </button>
        </div>
        {todo_list.todo_mid.map((item, index) => (
          <TodoCards
            key={item}
            todo={item}
            delete_function={delete_todo}
            position={1}
            index={index}
          />
        ))}
      </div>
      <div className="mobile_todo_main_card">
        <div className="mobile_todo_input">
          <div className="input-container">
            <input
              type="text"
              className="full-width-input"
              placeholder="low"
              id="mobile_todo_text_input_2"
            />
          </div>
          <button className="mobile_todo_button" onClick={() => add_todo(2)}>
            +
          </button>
        </div>
        {todo_list.todo_low.map((item, index) => (
          <TodoCards
            key={item}
            todo={item}
            delete_function={delete_todo}
            position={2}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
