import "./App.css";
import { Link } from "react-router-dom";
import Llkdfjsd from "../Svg/idk.png";

function App() {
  return (
    <div id="description-and-undraw">
      <div id="description">
        <div>
          <div id="description-header">
            <h1>Efficient Solutions: Your One-Stop Online Productivity Hub</h1>
          </div>
          <p>
            Empowering productivity through seamless tools, support, and
            connectivity for your everyday tasks and goalsv
          </p>
          <div id="description-button-contander">
            <Link to="/sineup" id="description-get-started-button">
              <p>Get Started</p>
            </Link>
            <Link to="/contactup" id="description-learn-more-button">
              <p>contact us</p>
            </Link>
          </div>
        </div>
      </div>
      <div id="undraw">
        <img src={Llkdfjsd} id="undraw-png" alt="logo" />
      </div>
    </div>
  );
}

export default App;
